import React, { Component } from 'react';

import CareersPage from '../sections/CareersPage'

class Careers extends Component {

    render() {
        return (
            <div>
                <CareersPage />
            </div>
        )
    }

}
export default Careers