import React, { Component } from 'react';
import { 
    Container, 
    MDBJumbotron,
    Row, 
    Col, 
 } from 'mdbreact';

class CareersPage extends Component {
    render() {

        return (
            <Container>
                <MDBJumbotron style={{ marginTop: "1rem" }}>
                    <h2 className="card-title"> Careers </h2>
					<h4><b><u> Active Job Postings </u></b></h4>
					
					<p>
						<br/><b>Cascade Holdings, LLC:</b>  Cascade Holdings, LLC seeks Estimator. Job site is located at 352 Gold Tip Drive, Orem, UT 84058. The position requires a Bachelor’s degree or its foreign equivalent in Cost Estimating, Industrial Engineering, Construction Management, Engineering, or another related field and 12 months of experience in job offered, industrial engineering, project engineering, or another closely related engineering field.
					</p>
					<p>
						<b>Job Duties:</b> Position is responsible for doing all pre-construction plan measuring and order preparation to be entered into our system for successful job completion.
					</p>
					<p><center>
						IF INTERESTED, PLEASE E-MAIL RESUMES:<br/>
						<br/>
						Cascade Holdings, LLC:<br/>
						Kyle Widdison<br/>
						Owner<br/>
						kyle.widdison@cascadestucco.com<br/>

					</center></p>
                    

                    <hr className="my-2" />

                </MDBJumbotron>
				
            </Container>
        );
    };
}

export default CareersPage;