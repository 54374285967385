import React, { Component } from 'react';

import TeamPage from '../sections/TeamPage'

class About extends Component {

    render() {
        return (
            <div>
                <TeamPage />
            </div>
        )
    }

}
export default About