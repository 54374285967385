import React, { Component } from 'react';
import {
    // Container,
    Row,
    Col,
    //View,
    MDBJumbotron,
    //MDBCol, MDBRow, 
    MDBContainer,
    //MDBBtn,
    //MDBCard, MDBCardBody, MDBCardTitle, MDBCardText,
} from 'mdbreact';

// import ProfileCard from '../components/ProfileCard'
import Profile from '../components/Profile'

class TeamPage extends Component {

    render() {

        const MD = '4';
        const LG = '4';

        return (
            <MDBContainer>
                <MDBJumbotron style={{ marginTop: "1rem" }}>
                    <h2 className="card-title">This page is under construction (pun intended). Disregard the information below.</h2>
                    <p>
                        We owe all of our success to our wonderful employees:
                        they’re always on call and looking for ways to get more done in less time.
                    </p>
                    <p>
                        Our employees use innovative database technology that streamlines scheduling
                        and other essential business information, accessing critical data and workflows
                        from a single smartphone app.
                    </p>
                    <p>
                        We continue to innovate by borrowing from years of experience in different industries.
                    </p>
                    <hr className="my-2" />
                    
                </MDBJumbotron>

                    <Row>
                        <Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <Profile name={'Scott Hammond'} title={'Partner'}
                                img={require('../assets/img/profiles/Scot-HammondWB.jpg')}
                                bio={<div>
                                    Scott began his career over 20 years ago with a bachelor’s in psychology and a minor in Spanish.|
                                    Since then, he’s successfully run several companies crossing through different industries.
                                    At Cascade, Scott oversees all operations, scheduling, sales, and hiring,
                                    with too many other responsibilities to list.
                                    Scott has spent seven and a half months mastering the art of nunchucks & computer hacking
									from an elusive nun at the local Arby's. He has been tirelessly fighting the Utah legislature
									to allow him to own an armadillo fleet. His life goal is to some day be able to tell the difference
									between butter and I Can’t Believe It’s Not Butter.
                                    </div>
                                }
                            />
                        </Col>

                        <Col lg={LG} md={MD} className="mb-lg-0 mb-4">

                            <Profile name={'Ben Probst'} title={'Partner'}
                                img={require('../assets/img/profiles/Ben-ProbstWB.jpg')}
                                bio={<div>
                                    Ben is the contractor’s contractor—he’s done it all.|
                                    On the one hand, he’s a real estate expert and has bought and sold hundreds of properties.

                                    But the other hand is strong from years spent framing, tiling,
                                            painting—everything it takes to build a house from nothing.
                                    He spent 15 years building custom homes; his completed projects number in the hundreds.
                                    As a partner at Cascade, Ben takes care of field operations, installation processes, training,
                                    warranties, and asset acquisition and maintenance.
                                    He lives in Herriman, Utah, with his family, exploring the nearby canyons top to bottom.
                                </div>
                                } />

                        </Col>

                        <Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <Profile name={'Kyle Widdison'} title={'Partner'}
                                img={require('../assets/img/profiles/Kyle-WiddisonWB.jpg')}
                                bio={<div>
                                    Kyle joined Cascade as a partner after 15 years of management, financial strategy, and consulting.|
                                    In 2007 he cofounded Purqz, a property management company, where he served as COO.
                                    He also worked as an instructor for Manhattan GMAT,
                                    preparing the business leaders of tomorrow for what’s to come. 
									
                                    Kyle puts his experience to use today by overseeing all of Cascade’s pre-construction activities,
                                    financial functions, and information systems.
                                    He lives in Saratoga Springs, Utah,
                                    playing whatever sport you suggest and building a pen for his herd of eight children and three foster kids.
                                </div>
                                } />

                        </Col>
                    </Row>


                    <Row>
                        

                        <Col lg={LG} md={MD} className="mb-lg-0 mb-4">

                            <Profile name={'Abe Fisher'} title={'Materials Management'}
                                img={require('../assets/img/profiles/abe.jpg')}
                                bio={<div>
                                    Abe orders all our materials. There's a reasonable likelihood he does other stuff too, but nobody is really sure.| Abe graduated with a Ph.D. from the business school of Nunya in 1925. 
									He enjoys finding ways to get lunch for free, yelling at estimators, and complaining about superintendents.
                                    </div>
                                } />

                        </Col>

                        <Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <Profile name={'Monica'} title={'Scheduler? Or something like that'}
                                img={require('../assets/img/profiles/Monica.jpg')}
                                bio={<div>
                                    Monica is in charge of witholding work from guys who complain too much.
                                    |Some guys will call and text every single day asking if there's new work and it's like dude?
									we will tell you when there's more work. Maybe there's no work because you keep asking, guy.
                                </div>
                                } />

                        </Col>
						
						<Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <Profile name={'Kevin Barton'} title={'Estimating'}
                                img={require('../assets/img/profiles/kevin.jpg')}
                                bio={<div>
                                    Kevin takes care of estimating, when he feels like it. Good luck convincing him otherwise.
                                    |If Kevin hasn't shown up by 10am, it means he's eating waffles. Don't be alarmed, it's just part of the job (or so he says). Don't try calling him, because he'll just tell you to call somebody else.
                                </div>
                                } />

                        </Col>
                    </Row>
            
            </MDBContainer>
        )
    }

}

export default TeamPage;